import React, { Component, ChangeEvent, FormEvent } from "react";
import "./CreateForm.css";
import ShareCreator from "./ShareCreator";

interface State {
  secret?: number;
  shareCount: number;
  decodeCount: number;
  secretErrors: ReadonlyArray<string>;
  shareCountErrors: ReadonlyArray<string>;
  decodeCountErrors: ReadonlyArray<string>;
}

interface Props {
  onValid: (shareCreator: ShareCreator) => void;
}

type FieldName = "secret" | "shareCount" | "decodeCount";
type NumericFieldState = { [key in FieldName]?: number | null };

class CreateForm extends Component<Props> {
  static minCount = 2;
  static maxCount = 100;

  readonly state: State = {
    shareCount: CreateForm.minCount,
    decodeCount: CreateForm.minCount,
    secretErrors: [],
    shareCountErrors: [],
    decodeCountErrors: []
  };

  handleNumericChange = (
    field: FieldName,
    e: ChangeEvent<HTMLInputElement>
  ) => {
    let newValue = null;
    let newState: NumericFieldState = {};

    if (e.currentTarget.value != "") {
      newValue = Number(e.currentTarget.value);
    }
    newState[field] = newValue;

    this.setState(newState);
  };

  validate = (e: FormEvent<HTMLFormElement>) => {
    if (e.type != "submit") {
      return;
    }

    // clear any existing form errors
    this.setState({
      secretErrors: [],
      shareCountErrors: [],
      decodeCountErrors: []
    });

    let secretErrors: Array<string> = [];
    if (
      this.state.secret === null ||
      this.state.secret === undefined ||
      isNaN(this.state.secret)
    ) {
      secretErrors.push("this needs to be a... number!");
    }
    this.setState({ secretErrors });

    // prettier-ignore
    const outOfRangeError = `this has to be between ${CreateForm.minCount} and ${CreateForm.maxCount}`;

    let shareCountErrors: Array<string> = [];
    if (this.state.shareCount === null || isNaN(this.state.shareCount)) {
      shareCountErrors.push("this needs to be a... number!");
    } else {
      if (
        this.state.shareCount < CreateForm.minCount ||
        this.state.shareCount > CreateForm.maxCount
      ) {
        shareCountErrors.push(outOfRangeError);
      }
      if (this.state.shareCount < this.state.decodeCount) {
        shareCountErrors.push(
          "this has to be equal to or bigger than the number of shares needed to decode"
        );
      }
    }
    this.setState({ shareCountErrors });

    let decodeCountErrors: Array<string> = [];
    if (this.state.decodeCount === null || isNaN(this.state.decodeCount)) {
      decodeCountErrors.push("this needs to be a... number!");
    } else {
      if (
        this.state.decodeCount < CreateForm.minCount ||
        this.state.decodeCount > CreateForm.maxCount
      ) {
        decodeCountErrors.push(outOfRangeError);
      }
      if (this.state.shareCount < this.state.decodeCount) {
        decodeCountErrors.push(
          "this has to be equal to or smaller than the number of shares to create"
        );
      }
    }
    this.setState({ decodeCountErrors });

    if (
      secretErrors.length == 0 &&
      shareCountErrors.length == 0 &&
      decodeCountErrors.length == 0
    ) {
      const shareCreator: ShareCreator = new ShareCreator(
        this.state.secret as number,
        this.state.shareCount as number,
        this.state.decodeCount as number
      );
      this.props.onValid(shareCreator);
    }

    e.preventDefault();
  };

  render() {
    const {
      secret,
      shareCount,
      decodeCount,
      secretErrors,
      shareCountErrors,
      decodeCountErrors
    } = this.state;

    return (
      <form className="CreateForm" onSubmit={this.validate} noValidate>
        <div className="CreateForm-field">
          <label htmlFor="secret">Your secret number</label>
          <input
            type="number"
            name="secret"
            value={secret || ""}
            onChange={e => this.handleNumericChange("secret", e)}
            className="CreateForm-secret"
          />
          {secretErrors.map(error => (
            <div key={error} className="CreateForm-error">
              {error}
            </div>
          ))}
        </div>

        <div className="CreateForm-field">
          <label htmlFor="sharesCount">Number of shares to create</label>
          <input
            type="number"
            name="sharesCount"
            value={shareCount || ""}
            onChange={e => this.handleNumericChange("shareCount", e)}
            className="CreateForm-count"
          />
          {shareCountErrors.map(error => (
            <div key={error} className="CreateForm-error">
              {error}
            </div>
          ))}
        </div>

        <div className="CreateForm-field">
          <label htmlFor="decodeCount">Number of shares needed to decode</label>
          <input
            type="number"
            name="decodeCount"
            value={decodeCount || ""}
            onChange={e => this.handleNumericChange("decodeCount", e)}
            className="CreateForm-count"
          />
          {decodeCountErrors.map(error => (
            <div key={error} className="CreateForm-error">
              {error}
            </div>
          ))}
        </div>

        <div>
          <button className="CreateForm-submit" type="submit">
            Create Shares
          </button>
        </div>
      </form>
    );
  }
}

export default CreateForm;

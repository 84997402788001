import React, { Component } from "react";
import { RouteComponentProps } from "react-router";
import CreateForm from "./CreateForm";
import DisplayShares from "./DisplayShares";
import ShareCreator from "./ShareCreator";

type Status = "create" | "display";

interface State {
  status: Status;
  shareCreator?: ShareCreator;
  shares?: ReadonlyArray<number>;
}

class CreateShares extends Component<RouteComponentProps> {
  readonly state: State = {
    status: "create"
  };

  createShares = (shareCreator: ShareCreator) => {
    const shares = shareCreator.createShares();
    this.setState({ shareCreator, shares, status: "display" });
  };

  component = () => {
    switch (this.state.status) {
      case "create":
        return <CreateForm onValid={this.createShares} />;
      case "display":
        return (
          <DisplayShares shares={this.state.shares as ReadonlyArray<number>} />
        );
    }
  };

  render() {
    return <div className="Shares">{this.component()}</div>;
  }
}

export default CreateShares;

import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import "./Header.css";

class Header extends Component {
  render() {
    return (
      <header className="Header">
        <h1 className="Header-name">
          <strong>Poly</strong>Share
        </h1>
        <nav>
          <ul className="Header-nav">
            <li className="Header-navlink">
              <NavLink to="/shares">Create</NavLink>
            </li>
            <li className="Header-navlink">
              <NavLink to="/decode">Decode</NavLink>
            </li>
          </ul>
        </nav>
      </header>
    );
  }
}

export default Header;

import React, { ChangeEvent, Component, FormEvent } from "react";
import "./DecodeForm.css";
import ShareCreator, { Point } from "./ShareCreator";

interface State {
  shareIndex: number | "";
  shareValue: number | "";
  shareIndexErrors: Array<string>;
  shareValueErrors: Array<string>;
  shares: ReadonlyArray<Point>;
  secret?: number;
}

type FieldName = "shareIndex" | "shareValue";
type NumericFieldState = { [key in FieldName]?: number | null };

class DecodeForm extends Component {
  readonly state: State = {
    shareIndex: "",
    shareValue: "",
    shares: [],
    shareIndexErrors: [],
    shareValueErrors: []
  };

  handleNumericChange = (
    field: FieldName,
    e: ChangeEvent<HTMLInputElement>
  ) => {
    let newValue = null;
    let newState: NumericFieldState = {};

    if (e.currentTarget.value != "") {
      newValue = Number(e.currentTarget.value);
    }
    newState[field] = newValue;

    this.setState(newState);
  };

  validate = (e: FormEvent<HTMLFormElement>) => {
    if (e.type != "submit") {
      return;
    }

    this.setState({
      shareIndexErrors: [],
      shareValueErrors: []
    });

    let shareIndexErrors: Array<string> = [];
    if (
      this.state.shareIndex === "" ||
      isNaN(this.state.shareIndex) ||
      this.state.shareIndex < 1
    ) {
      shareIndexErrors.push("this needs to be a positive number!");
    } else {
      if (
        this.state.shares.map(share => share.x).includes(this.state.shareIndex)
      ) {
        shareIndexErrors.push("that share has already been entered");
      }
    }
    this.setState({ shareIndexErrors });

    let shareValueErrors: Array<string> = [];
    if (this.state.shareValue === "" || isNaN(this.state.shareValue)) {
      shareValueErrors.push("this needs to be a number!");
    }
    this.setState({ shareValueErrors });

    if (shareIndexErrors.length == 0 && shareValueErrors.length == 0) {
      const share = new Point(
        this.state.shareIndex as number,
        this.state.shareValue as number
      );

      const newShares = this.state.shares.concat(share);
      newShares.sort((a, b) => a.x - b.x);
      const secret = ShareCreator.decodeSecret(newShares);
      this.setState({
        shareIndex: "",
        shareValue: "",
        shares: newShares,
        secret
      });
    }

    e.preventDefault();
  };

  renderSecret() {
    if (!this.state.secret) {
      return null;
    }

    return (
      <div>
        <hr />
        <h2>
          Secret value:{" "}
          <span className="DecodeForm-secret">{this.state.secret}</span>
        </h2>

        <p>Calculated from these shares:</p>
        <ol>
          {this.state.shares.map(share => (
            <li key={share.x} value={share.x}>
              {share.y}
            </li>
          ))}
        </ol>
      </div>
    );
  }

  render() {
    return (
      <div>
        <h2 className="DecodeForm-header">Enter shares</h2>
        <form className="DecodeForm" onSubmit={this.validate} noValidate>
          <label htmlFor="shareIndex">Number</label>
          <div>
            <input
              type="number"
              name="shareIndex"
              className="DecodeForm-count"
              onChange={e => this.handleNumericChange("shareIndex", e)}
              value={this.state.shareIndex}
            />
            {this.state.shareIndexErrors.map(error => (
              <div key={error} className="DecodeForm-error">
                {error}
              </div>
            ))}
          </div>
          <label htmlFor="shareIndex">Value</label>
          <div>
            <input
              type="number"
              name="shareValue"
              onChange={e => this.handleNumericChange("shareValue", e)}
              value={this.state.shareValue}
            />
            {this.state.shareValueErrors.map(error => (
              <div key={error} className="DecodeForm-error">
                {error}
              </div>
            ))}
          </div>

          <button className="DecodeForm-submit" type="submit">
            Enter
          </button>
        </form>
        {this.renderSecret()}
      </div>
    );
  }
}

export default DecodeForm;

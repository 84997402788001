import React, { Component } from "react";
import "./DisplayShares.css";

interface Props {
  shares: ReadonlyArray<number>;
}

class DisplayShares extends Component<Props> {
  render() {
    const shareCount = this.props.shares.length;

    return (
      <div>
        <h2 className="DisplayShares-heading">The {shareCount} shares are:</h2>
        <ol className="DisplayShares-list">
          {this.props.shares.map((share, i) => (
            <li key={i}>
              <span className="DisplayShares-share">{share}</span>
            </li>
          ))}
        </ol>
      </div>
    );
  }
}

export default DisplayShares;

import React, { Component } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import Header from "./Header";
import DisplayShares from "./DisplayShares";
import DecodeForm from "./DecodeForm";
import "./App.css";
import CreateShares from "./CreateShares";

class App extends Component {
  render() {
    return (
      <div className="App">
        <Header />
        <div className="content">
          <Switch>
            <Route path="/shares" component={CreateShares} />
            <Route path="/decode" component={DecodeForm} />
            <Route path="/display-shares" component={DisplayShares} />
            <Route exact path="/">
              <Redirect to="shares" />
            </Route>
          </Switch>
        </div>
      </div>
    );
  }
}

export default App;

const mod = (value: number, mod: number) => {
  return value - mod * Math.floor(value / mod);
};

class ShareCreator {
  secret: number;
  shareCount: number;
  decodeCount: number;

  // This is the largest 32 bit prime
  static p: number = 4294967291;

  constructor(secret: number, shareCount: number, decodeCount: number) {
    this.secret = secret;
    this.shareCount = shareCount;
    this.decodeCount = decodeCount;
  }

  createShares(): ReadonlyArray<number> {
    let coefficients: Array<number> = [];
    coefficients[0] = this.secret;
    for (let i = 1; i < this.decodeCount; i++) {
      coefficients[i] = Math.floor(Math.random() * ShareCreator.p);
    }
    const polynomial = new Polynomial(coefficients);

    let shares: Array<number> = [];
    // note going from 1-count not 0-count-1 here
    // so that the value displayed as the index is the x value used for the calculation
    for (let i = 1; i <= this.shareCount; i++) {
      shares[i - 1] = mod(polynomial.evaluate(i), ShareCreator.p);
    }
    return shares;
  }

  static decodeSecret(shares: Array<Point>): number {
    const f = interpolate(shares, ShareCreator.p);
    return mod(f.evaluate(0), ShareCreator.p);
  }
}

export default ShareCreator;

function trimZeros(coefficients: Array<number>) {
  if (coefficients.length == 0) {
    return coefficients;
  }
  if (coefficients[coefficients.length - 1] != 0) {
    return coefficients;
  }

  let lastIndex = coefficients.length - 1;
  while (lastIndex >= 0) {
    if (coefficients[lastIndex] != 0) {
      return coefficients.splice(0, lastIndex + 1);
    }
    lastIndex -= 1;
  }

  return [];
}

export class Polynomial {
  static indeterminate = "x";

  coefficients: Array<number>;

  constructor(coefficients: Array<number>) {
    this.coefficients = trimZeros(coefficients);
  }

  // This uses Horner's method
  evaluate(x: number): number {
    return this.coefficients.reduceRight((sum, c) => sum * x + c, 0);
  }

  evaluateMod(x: number, p: number) {
    return mod(this.evaluate(x), p);
  }

  add(other: Polynomial): Polynomial {
    let longestLength = Math.max(
      this.coefficients.length,
      other.coefficients.length
    );
    let c: Array<number> = [];

    for (let i = 0; i < longestLength; i++) {
      c[i] = (this.coefficients[i] || 0) + (other.coefficients[i] || 0);
    }

    return new Polynomial(c);
  }

  multiply(other: Polynomial): Polynomial {
    if (this.coefficients.length == 0 || other.coefficients.length == 0) {
      return ZeroPolynomial;
    }
    const newLength = this.coefficients.length + other.coefficients.length - 1;
    let newCoefficients = new Array(newLength);
    newCoefficients.fill(0);

    this.coefficients.forEach((a, i) => {
      other.coefficients.forEach((b, j) => {
        newCoefficients[i + j] = newCoefficients[i + j] + a * b;
      });
    });

    return new Polynomial(trimZeros(newCoefficients));
  }

  // the coefficients will always be integers so round them before doing the modulus to
  // handle js using floats under the hood
  roundMod(p: number): Polynomial {
    return new Polynomial(this.coefficients.map(c => mod(Math.round(c), p)));
  }

  toString(): string {
    return this.coefficients
      .map((a, i) => {
        switch (i) {
          case 0:
            return `${a}`;
          case 1:
            return `${a}${Polynomial.indeterminate}`;
          default:
            return `${a}${Polynomial.indeterminate}^${i}`;
        }
      })
      .join(" + ");
  }
}

export const ZeroPolynomial = new Polynomial([]);

export class Point {
  readonly x: number;
  readonly y: number;

  constructor(x: number, y: number) {
    this.x = x;
    this.y = y;
  }
}

const singleTerm = (points: Array<Point>, i: number): Polynomial => {
  let term = new Polynomial([1.0]);
  const pi = points[i];
  const xi = pi.x;
  const yi = pi.y;

  points.forEach((p, j) => {
    if (j === i) {
      return;
    }

    const xj = p.x;
    const poly = new Polynomial([-xj / (xi - xj), 1.0 / (xi - xj)]);
    term = term.multiply(poly);
  });

  term = term.multiply(new Polynomial([yi]));

  return term;
};

export const interpolate = (points: Array<Point>, p: number): Polynomial => {
  if (points.length === 0) {
    throw "need at least one point to interpolate";
  }

  const xValues = points.map(point => point.x);
  const xSet = new Set(xValues);
  if (xSet.size !== xValues.length) {
    throw "not all x values are distinct";
  }

  let terms: Array<Polynomial> = [];

  for (let i = 0; i < points.length; i++) {
    terms[i] = singleTerm(points, i);
  }
  const polynomial = terms.reduce((sum, term) => {
    return sum.add(term);
  }, ZeroPolynomial);

  return polynomial.roundMod(p);
};
